<template>
  <div class="create-material">

    <ol class="create-material-tip">
      <li>物料损耗是指每制作单杯饮品所需要的原材料分量</li>
      <li>物料库存预警是建立饮品与物料关联关系基础上</li>
      <li>甄选每一种原材料，只为一杯好咖啡</li>
      <li>点击下方<span class="create-material-tip-alarm">「选取物料」</span>添加原材料</li>
    </ol>

    <div class="create-material-table" v-for="(spec, tIdx) in drink.drinkSpec" :key="tIdx">
      <p class="create-material-table-title">{{spec.name}}-所需物料
        <el-button type="text" @click="handleShowMaterial(tIdx)">选取物料</el-button>
      </p>
      <el-row class="create-material-table-header">
        <el-col :span="10" class="item-name">物料名称</el-col>
        <el-col :span="6" class="item-name">单杯消耗</el-col>
        <el-col :span="4" class="item-name">单位</el-col>
        <el-col :span="4" class="item-name">操作</el-col>
      </el-row>

      <el-row class="create-material-table-item" v-for="(item, idx) in spec.materials" :key="idx">
        <el-col :span="10" class="item">{{item.name}}</el-col>
        <el-col :span="6" class="item">
          <el-input-number size="small" v-model.number="item.consume"></el-input-number>
        </el-col>
        <el-col :span="4" class="item">{{item.unitText}}</el-col>
        <el-col :span="4" class="item opr" @click="handleMaterialRemove(tIdx, idx)">移除</el-col>
      </el-row>
    </div>
    <div class="create-material-submit">
      <el-button type="primary" @click="$emit('preview')" ghost>上一步</el-button>
      <el-button type="primary" @click="$emit('next')" :disabled="!canNext">下一步</el-button>
    </div>

    <el-dialog
      :visible.sync="showMaterial"
      title="选取物料"
      width="418px"
    >
      <el-form label-width="80px">
        <el-form-item label="物料分类">
          <el-select
            @change="materialMates"
            v-model="material.mateSortUuid">
            <el-option :value="ms.uuid" v-for="(ms, key) in mateSorts" :label="ms.name" :key="key">
              {{ms.name}}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="物料名称">
          <el-select v-model="material.materialUuid">
            <el-option :value="mate.uuid" v-for="(mate, key) in materials" :label="mate.name" :key="key">
              {{mate.name}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单杯消耗">
          <el-input-number v-model.number="material.number"/>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleBindMaterial">确认</el-button>
          <el-button size="small" @click="showMaterial = false">取消</el-button>
        </el-form-item>
      </el-form>


    </el-dialog>

  </div>

</template>

<script>
  import {
    materialSorts, materialMates
  } from '@/api/org/material'

  export default {
    name: '',
    components: {},
    props: {
      drink: {
        type: Object,
        default: () => {
          return {
            drinkSpec:[]
          }
        }
      }
    },
    mounted: function () {
      let canNext = true
      for (let i = 0; i < this.drink.drinkSpec.length; i++) {
        if (!this.drink.drinkSpec[i].materials) {
          canNext = false
        }
      }
      this.canNext = canNext
      this.loadMaterialSort()
    },
    data: function () {
      return {
        canNext: false,
        showMaterial: false,
        idx: 0,
        mateSorts: [],
        materials: [],
        material: {
          mateSortUuid: '',
          materialUuid: '',
          number: 0
        },
      }
    },
    methods: {
      handleBindMaterial() {
        let ms = this.mateSorts.find((item) => { return item.uuid === this.material.mateSortUuid})
        let material = this.materials.find((item) => { return item.uuid === this.material.materialUuid})
        this.drink.drinkSpec[this.idx].materials = this.drink.drinkSpec[this.idx].materials || []
        this.drink.drinkSpec[this.idx].materials.push({
          uuid:material.uuid,
          name:material.name,
          unitText:ms.unit,
          consume:this.material.number
        })
        this.showMaterial = false

        let canNext = true
        for (let i = 0; i < this.drink.drinkSpec.length; i++) {
          if (!this.drink.drinkSpec[i].materials) {
            canNext = false
          }
        }
        this.canNext = canNext
      },
      handleMaterialRemove (tIdx, idx) {
        this.drink.drinkSpec[tIdx].materials.splice(idx, 1)
      },
      handleShowMaterial(idx) {
        this.loadMaterialSort()
        this.idx = idx
        this.materials = []
        this.showMaterial = true
      },
      loadMaterialSort() {
        this.material.materialUuid = ''
        this.material.number = 0
        materialSorts().then(res => {
          this.mateSorts = res.data || []
        })
      },
      materialMates() {
        materialMates({materialSortUuid: this.material.mateSortUuid}).then(res => {
          let data = res.data || []
          let materials = this.drink.drinkSpec[this.idx].materials || []
          for (let i = 0; i < materials.length; i++) {
            let idx = data.findIndex((d) => {
              return d.uuid == materials[i].uuid
            })
            if (idx > 0) {
              data.splice(idx, 1)
            }
          }
          this.materials = data
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .create-material {

    &-tip {
      counter-reset: section;
      @include font-little();
      padding: $small-space $middle-space;
      margin-bottom: $middle-space;

      li:before {
        counter-increment: section; /* 增加计数器值 */
        content: counter(section) ". ";
      }

      &-alarm {
        color: $brand-warning;
      }
    }

    &-table {
      min-height: 300px;

      &-title {
        @include font-medium();
        font-weight: 500;
      }

      &-header {
        height: 32px;
        line-height: 32px;
        background-color: #f5f5f5;
        border-radius: $box-mini-radius;

        .item-name {
          @include font-little();
          line-height: 32px;
        }

        .item-name:not(:first-child) {
          text-align: center;
        }

        .item-name:first-child {
          padding-left: $middle-space;
        }
      }

      &-item {
        padding: $small-space 0;


        border-bottom: 1px solid $content-border-color;

        .opr {
          color: $theme-color;
          cursor: pointer;
        }

        .opr:hover {
          color: $brand-info;
        }

        .item:not(:first-child) {
          text-align: center;
        }

        .item:first-child {
          padding-left: $middle-space;
          color: $color-black;
        }
      }

    }


    &-submit {
      margin-top: $small-space;
      text-align: center;
    }

  }
</style>
